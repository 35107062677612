export enum FollowUpActionStatusType {
	FRESH = "fresh",
	FRESH_2ND_PHASE = "fresh-2nd-phase",
	MODIFICATION_SELECTED = "modification-selected",
	TRANSITION_SELECTED = "transition-selected",
	EXTRAS_SELECTED = "extras-selected",
	FEATURES_SELECTED = "features-selected",
	INTERMEDIATE_SHOPPING_CART_CONFIGURED = "intermediate-shopping-cart-configured",
	INTERMEDIATE_SHOPPING_CART_SAVED = "intermediate-shopping-cart-saved",
	INTERMEDIATE_SHOPPING_CART_VOIP_HANDLED = "intermediate-shopping-cart-voip-handled",
	SHOPPING_CART_CONFIGURED = "shopping-cart-configured",
	SHOPPING_CART_SAVED = "shopping-cart-saved",
	INTERMEDIATE_ORDER_DATA_SAVED = "intermediate-order-data-saved",
	INTERMEDIATE_ORDER_SENT = "intermediate-order-sent",
	INTERMEDIATE_ORDER_COMPLETED = "intermediate-order-completed",
	ORDER_DATA_SAVED = "order-data-saved",
	UNBUNDLE_STEP = "unbundle-step",
	DELETE_STEP = "delete-step",
	DELETE_BLOCKING_STEP = "delete-blocking-step",
	BUNDLE_STEP = "bundle-step",
	COMPLETED = "completed",
	READY_TO_GROUP = "ready-to-group",
	GROUPED = "grouped",
	ADDRESS_SEARCH = "address-search",
	ADDRESS_SELECTED = "address-selected",
	APARTMENT_SELECTED = "apartment-selected"
}

const STEPS_STATUSES: FollowUpActionStatusType[] = [
	FollowUpActionStatusType.UNBUNDLE_STEP,
	FollowUpActionStatusType.DELETE_STEP,
	FollowUpActionStatusType.DELETE_BLOCKING_STEP,
	FollowUpActionStatusType.BUNDLE_STEP,
	FollowUpActionStatusType.GROUPED
];

export const BUNDLE_DISABLED_STATUS: FollowUpActionStatusType[] = [...STEPS_STATUSES, FollowUpActionStatusType.COMPLETED];

export const PROMOTION_DISABLED_STATUS: FollowUpActionStatusType[] = [...BUNDLE_DISABLED_STATUS, FollowUpActionStatusType.FRESH];

export const CONFIGURATION_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...PROMOTION_DISABLED_STATUS,
	FollowUpActionStatusType.MODIFICATION_SELECTED,
	FollowUpActionStatusType.TRANSITION_SELECTED,
	FollowUpActionStatusType.EXTRAS_SELECTED
];

export const ORDER_DATA_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...CONFIGURATION_DISABLED_STATUS,
	FollowUpActionStatusType.FEATURES_SELECTED,
	FollowUpActionStatusType.SHOPPING_CART_SAVED
];

const CHANGE_OF_ADDRESS_1ST_PHASE_STATUSES: FollowUpActionStatusType[] = [
	FollowUpActionStatusType.FRESH,
	FollowUpActionStatusType.ADDRESS_SEARCH,
	FollowUpActionStatusType.ADDRESS_SELECTED,
	FollowUpActionStatusType.APARTMENT_SELECTED,
	FollowUpActionStatusType.INTERMEDIATE_SHOPPING_CART_CONFIGURED,
	FollowUpActionStatusType.INTERMEDIATE_SHOPPING_CART_SAVED,
	FollowUpActionStatusType.INTERMEDIATE_SHOPPING_CART_VOIP_HANDLED,
	FollowUpActionStatusType.INTERMEDIATE_ORDER_DATA_SAVED,
	FollowUpActionStatusType.INTERMEDIATE_ORDER_SENT,
	FollowUpActionStatusType.INTERMEDIATE_ORDER_COMPLETED
];

const CHANGE_OF_ADDRESS_2ST_PHASE_STATUSES: FollowUpActionStatusType[] = [
	FollowUpActionStatusType.FRESH_2ND_PHASE,
	FollowUpActionStatusType.SHOPPING_CART_CONFIGURED,
	FollowUpActionStatusType.SHOPPING_CART_SAVED,
	FollowUpActionStatusType.ORDER_DATA_SAVED
];

export const COVERAGE_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	FollowUpActionStatusType.INTERMEDIATE_ORDER_SENT,
	FollowUpActionStatusType.INTERMEDIATE_ORDER_COMPLETED,
	...CHANGE_OF_ADDRESS_2ST_PHASE_STATUSES,
	...STEPS_STATUSES,
	FollowUpActionStatusType.COMPLETED
];

export const INTERMEDIATE_CONFIGURATION_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...COVERAGE_CHANGE_OF_ADDRESS_DISABLED_STATUS,
	FollowUpActionStatusType.FRESH,
	FollowUpActionStatusType.ADDRESS_SEARCH,
	FollowUpActionStatusType.ADDRESS_SELECTED
];

export const INTERMEDIATE_ORDER_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...INTERMEDIATE_CONFIGURATION_CHANGE_OF_ADDRESS_DISABLED_STATUS,
	FollowUpActionStatusType.APARTMENT_SELECTED
];

export const PROMOTION_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...CHANGE_OF_ADDRESS_1ST_PHASE_STATUSES,
	...STEPS_STATUSES,
	FollowUpActionStatusType.COMPLETED
];

export const CONFIGURATION_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...PROMOTION_CHANGE_OF_ADDRESS_DISABLED_STATUS,
	FollowUpActionStatusType.FRESH_2ND_PHASE
];

export const ORDER_CHANGE_OF_ADDRESS_DISABLED_STATUS: FollowUpActionStatusType[] = [
	...CONFIGURATION_CHANGE_OF_ADDRESS_DISABLED_STATUS,
	FollowUpActionStatusType.FEATURES_SELECTED,
	FollowUpActionStatusType.SHOPPING_CART_SAVED
];
