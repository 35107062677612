import httpClient from "@/api/config/httpClient";
import {
	Customer,
	SearchCustomerFilters,
	CustomerFollowUpFilter,
	TerminateProductOrderData,
	ProductStates,
	CustomerAggrupation
} from "@/api/models/customer";
import { ModificationCheck } from "@/api/models/product-modification";
import { TablePaginationFilter } from "@/models/table.model";
import { AxiosResponse } from "axios";
import { MultipleTransitionBundlesData } from "../models/product-multiple-transition/productMultipleTransition.model";
import { ProductTransitionGroup } from "../models/product-transition/productTransitionGroup.model";
import { transitionCheckToTransitionCheckAPI } from "../adapters/product-transition/productTransition.adapter";
import { TransitionCheck } from "../models/product-transition/productTransition.model";
import { modificationCheckToModificationCheckAPI } from "../adapters/product-modification/productModification.adapter";

export abstract class CustomerService {
	private static readonly CUSTOMER_URL = "/sys03/customer";
	private static readonly DASHBOARD_URL = CustomerService.CUSTOMER_URL.concat("/dashboard");
	private static readonly DASHBOARD_CSV_URL = CustomerService.DASHBOARD_URL.concat("/csv");

	private static getFiltersParameters({
		filters
	}: {
		filters: SearchCustomerFilters | TablePaginationFilter;
	}): Record<string, string | number | boolean> {
		const queryFilterd = Object.entries(filters).filter(([, value]) => {
			if (typeof value === "string") {
				if (value === "all") return false;

				return value.length > 0;
			}
			return true;
		});

		return Object.fromEntries(queryFilterd);
	}

	public static getById({ id }: { id: string }): Promise<AxiosResponse<Customer>> {
		const url = `${CustomerService.CUSTOMER_URL}/${id}`;

		return httpClient.get(url);
	}

	public static getDashboardInformation(filters: SearchCustomerFilters) {
		const url = `${CustomerService.DASHBOARD_URL}`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardTransitionsById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/transitions`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardMultipleTransitionsById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/multiple-transitions`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardModificationsById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/modifications`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerDashboardNewExtrasById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/new-extras`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerDashboardTerminateById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/terminate`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerDashboardChangeOfAddressesById({ customerId, filters }: { customerId: string; filters: CustomerFollowUpFilter }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/dashboard/change-of-addresses`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardCsv(filters: SearchCustomerFilters) {
		const url = `${CustomerService.DASHBOARD_CSV_URL}`;
		const filtersWithoutPagination: SearchCustomerFilters = {
			...filters,
			page: undefined,
			limit: undefined
		};
		const params = CustomerService.getFiltersParameters({ filters: filtersWithoutPagination });

		return httpClient.get(url, { params });
	}

	public static getCustomerFinancesById(customerId: string, filters: TablePaginationFilter) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/finance`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerDocumentById(customerId: string, documentId: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/documents/${documentId}`;

		return httpClient.get(url, { responseType: "blob" });
	}

	public static getCustomerNotesById(id: string, filters: TablePaginationFilter) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/notes`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerTasksById(id: string, filters: TablePaginationFilter) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/tasks`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerTaskById(customerId: string, taskId: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/task/${taskId}`;
		return httpClient.get(url);
	}

	public static getCustomerOrdersById(id: string, filters: TablePaginationFilter) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/orders`;
		const params = CustomerService.getFiltersParameters({ filters });

		return httpClient.get(url, { params });
	}

	public static getCustomerOrderById(customerId: string, orderId: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/order/${orderId}`;
		return httpClient.get(url);
	}

	public static getCustomerChargesAndCreditsById(id: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/charges-and-credits`;
		return httpClient.get(url);
	}

	public static getCustomerAggrupationsById(id: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/aggrupations`;
		return httpClient.get(url);
	}

	public static createCustomerAggrupation(aggrupation: CustomerAggrupation) {
		const aggrupationsUrl = `${CustomerService.CUSTOMER_URL}/products/merge`;
		return httpClient.post(aggrupationsUrl, aggrupation);
	}

	public static unMergeCustomerAggrupation(aggrupation: CustomerAggrupation) {
		const { customerId, id: aggrupationId } = aggrupation;
		//const aggrupationsUrl = `${CustomerService.CUSTOMER_URL}/${customerId}/products/unmerge`;
		const aggrupationsUrl = `${CustomerService.CUSTOMER_URL}/${customerId}/aggrupations/${aggrupationId}/unmerge`;
		return httpClient.put(aggrupationsUrl, aggrupation);
	}

	public static sendCustomerAggrupation(aggrupation: CustomerAggrupation) {
		const { id: aggrupationId, customerId } = aggrupation;
		const aggrupationsUrl = `${CustomerService.CUSTOMER_URL}/${customerId}/aggrupations/${aggrupationId}/digitel/send`;
		return httpClient.put(aggrupationsUrl);
	}

	public static sendCustomerAggrupationToBss(aggrupation: CustomerAggrupation) {
		const { id: aggrupationId } = aggrupation;
		const aggrupationsUrl = `${CustomerService.CUSTOMER_URL}/aggrupations/${aggrupationId}/bss/send`;
		return httpClient.put(aggrupationsUrl);
	}

	public static getCustomerProductsForAggrupation({ customerId }: { customerId: string }) {
		const url = `${CustomerService.CUSTOMER_URL}/${customerId}/products-ready/`;
		return httpClient.get(url);
	}

	public static getCustomerProductsById(id: string, states?: ProductStates[]) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/products`;
		if (states && states.length > 0) {
			const params = new URLSearchParams();
			for (const state of states) {
				params.append("state", state);
			}

			return httpClient.get(url, {
				params
			});
		}

		return httpClient.get(url);
	}

	public static getCustomerProductById(id: string) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}`;
		return httpClient.get(url);
	}

	public static getCustomerProductTransitionGroups({ id, action, productId }: { id: string; action: string; productId: string }) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/product-transition-groups`;
		return httpClient.get<ProductTransitionGroup[]>(url, {
			params: { action, productId }
		});
	}

	/**
	 * Validate method to customer products actions
	 */
	public static getCustomerProductsActionValidateById(id: string, action: string) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/action/${action}/validate`;
		return httpClient.get(url);
	}

	public static getCustomerProductsActionDuplicateValidateById(id: string, action: string) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/action/${action}/validate/duplicate`;
		return httpClient.get(url);
	}

	/**
	 * Validate method to follow up actions
	 */
	public static getCustomerProductsActionOrderValidateById(id: string, action: string) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/action/${action}/validate/order`;
		return httpClient.get(url);
	}

	public static checkCustomerProductTransitionById(productId: string, transition: TransitionCheck) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${productId}/transition/check`;
		return httpClient.put<{ shoppingCartId: string }>(url, transitionCheckToTransitionCheckAPI(transition));
	}

	public static checkCustomerProductOnlyMobilesTransitionById(productId: string, transition: TransitionCheck) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${productId}/transition/only-mobiles/check`;
		return httpClient.put<{ shoppingCartId: string }>(url, transitionCheckToTransitionCheckAPI(transition));
	}

	public static checkCustomerProductSharedMobilesTransitionById(productId: string, transition: TransitionCheck) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${productId}/transition/shared-mobiles/check`;
		return httpClient.put<{ shoppingCartId: string }>(url, transitionCheckToTransitionCheckAPI(transition));
	}

	public static getCustomerProductTransitionById({ id, shoppingCartId }: { id: string; shoppingCartId: string }) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/transition`;
		return httpClient.get(url, {
			params: {
				shoppingCartId
			}
		});
	}

	public static getCustomerProductModificationById({ id, shoppingCartId }: { id: string; shoppingCartId: string }) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/modification`;
		return httpClient.get(url, {
			params: {
				shoppingCartId
			}
		});
	}

	public static checkCustomerProductModificationById({ productId, modification }: { productId: string; modification: ModificationCheck }) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${productId}/modification/check`;
		return httpClient.put<{ shoppingCartId: string }>(url, modificationCheckToModificationCheckAPI(modification));
	}

	public static terminateCustomerProductById({ id, order }: { id: string; order: TerminateProductOrderData }) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/terminate`;
		return httpClient.put(url, order);
	}

	public static getCustomerAvailableExtrasValidateById(id: string) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/available-extras/validate`;
		return httpClient.get(url);
	}

	public static getCustomerAvailableExtras({ id, shoppingCartId }: { id: string; shoppingCartId: string }) {
		const url = `${CustomerService.CUSTOMER_URL}/${id}/available-extras`;
		return httpClient.get(url, {
			params: {
				shoppingCartId
			}
		});
	}

	public static getCustomerProductOnlyMobilesTransitionBundles({
		id,
		shoppingCartId,
		groupId
	}: {
		id: string;
		shoppingCartId: string;
		groupId?: number;
	}) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/transition/only-mobiles`;
		return httpClient.get<MultipleTransitionBundlesData>(url, {
			params: {
				shoppingCartId,
				groupId
			}
		});
	}

	public static getCustomerProductSharedMobilesTransitionBundles({
		id,
		shoppingCartId,
		groupId
	}: {
		id: string;
		shoppingCartId: string;
		groupId?: number;
	}) {
		const url = `${CustomerService.CUSTOMER_URL}/product/${id}/transition/shared-mobiles`;
		return httpClient.get<MultipleTransitionBundlesData>(url, {
			params: {
				shoppingCartId,
				groupId
			}
		});
	}
}
